import './style.scss';
import $ from 'jquery';
import 'jquery-validation';

document.addEventListener('DOMContentLoaded', async function () {
    const myElem = document.querySelector('[id^="aopgImage"]');
    if (myElem) {
        const myElemId = myElem?.id.split('-')[1];
        const url = myElem?.dataset.domen;
        $.ajax({
            url: url+'/wp-json/wp/v2/pages/'+myElemId,
            method: 'GET',
            dataType: 'json',
            cache: false,
            processData: false,
            crossDomain: true,
        }).done(function (response) {
            // console.log('response', response);
            if (response.content) {
                const rawHtml = response.content.rendered;
                // console.log(rawHtml);
                myElem.insertAdjacentHTML('beforeend', rawHtml);
                const link = document.createElement('link');
                link.id = 'aopg-blocks-style-css';
                link.rel = 'stylesheet';
                link.type = 'text/css';
                link.href = url+`/wp-content/themes/interactive-image-builder/dist/style.css`;
                link.media = 'all';
                document.getElementsByTagName('head')[0].appendChild(link);

                points_handler();
                formWorker(myElemId);
            }
        });
    } else {
        points_handler();
        formWorker(window.imageID);
    }
});

const open = (e) => {
    const wrap = document.querySelector('.wp-block-aopg-blocks-imageblock');
    const current_card = e.target.nextSibling;
    close_cards(e.target);
    current_card.style.display = 'block';
    const current_point_top = e.target.style.top.split(/[( % - )]/);
    if (current_point_top[1] > 15) {
        current_card.querySelector('span').style.top = (wrap.offsetHeight * 0.05 + 15) / current_card.clientHeight * 100 + '%';
    } else {
         current_card.querySelector('span').style.top = '15px';
    }

    var pointCard = document.querySelectorAll('.point-card');
    var pointCardOri = document.querySelectorAll('.point-card');
    var arrowCard = document.querySelectorAll('.arrow');
    
    if (window.innerWidth <= 450){
        for (let i = 0; i < pointCard.length; i++) {
            var pointCardPositionLeft = pointCard[i].getBoundingClientRect().left;
            var pointCardPositionRight = pointCard[i].getBoundingClientRect().right;
            var pointCardPositionTop = pointCard[i].getBoundingClientRect().top;
            var pointCardPositionWidth = pointCard[i].getBoundingClientRect().width;
            let screenHeight = window.innerHeight;
            let screenWidth = window.innerWidth;
            var positionLeft = parseFloat(pointCardPositionLeft.toFixed(2))/parseFloat(screenWidth.toFixed(2));
            var positionRight = parseFloat(pointCardPositionRight.toFixed(2))/parseFloat(screenWidth.toFixed(2));
            var positionTop = parseFloat(pointCardPositionTop.toFixed(2))/parseFloat(screenHeight.toFixed(2));
            console.log('PIX Num. = ', i);
            console.log('Before left (pt) = ', pointCardPositionLeft);
            console.log('Before right (pt) = ', pointCardPositionRight);
            console.log('Position left ratio = ', positionLeft);
            console.log('Position right ratio = ', positionRight);
            console.log('Position top ratio = ', positionTop);
            if (positionLeft >= 0.02 && positionLeft <= 0.9 && positionRight >= 0.02 && positionRight <= 0.9) {
                console.log('PIX Num. = ', i);
                console.log("Width fit already");
                // if (positionTop < 0.1) {
                //     arrowCard[i].style.display = 'none';
                //     pointCard[i].style.top = "40px";
                // }
            } else if (positionLeft < 0.02 && positionRight >= 0.02 && positionRight <= 0.9){
                console.log('PIX Num. = ', i);
                console.log("Translated to the left, not fit initially");
                arrowCard[i].style.display = 'none';
                // let b;
                // b = window.innerWidth - pointCardPositionWidth;
                // pointCard[i].style.marginLeft = b/2 + 25 + "px";
                let a = 15;
                let b = a - pointCard[i].getBoundingClientRect().left;
                console.log('b = ', b);
                pointCard[i].style.marginLeft = b + "px";
                let rectTwoLeft = pointCard[i].getBoundingClientRect().left;
                let rectTwoRight = pointCard[i].getBoundingClientRect().right;
                console.log('After left (px) = ', rectTwoLeft);
                console.log('After right (px) = ', rectTwoRight);
            } else if (positionLeft >= 0.02 && positionLeft <= 0.9 && positionRight > 0.9){
                console.log('PIX Num. = ', i);
                console.log("Translated to the right, not fit initially");
                arrowCard[i].style.display = 'none';
                // let b;
                // b = window.innerWidth - pointCardPositionWidth;
                // pointCard[i].style.marginLeft = - b/2 - 25 + "px";
                let a = 20;
                let b = pointCard[i].getBoundingClientRect().left - a;
                pointCard[i].style.marginLeft = - b + "px";
                let rectThreeLeft = pointCard[i].getBoundingClientRect().left;
                let rectThreeRight = pointCard[i].getBoundingClientRect().right;
                console.log('After left (px) = ', rectThreeLeft);
                console.log('After right (px) = ', rectThreeRight);
            } else {
                let rectFourLeft = pointCard[i].getBoundingClientRect().left;
                let rectFourRight = pointCard[i].getBoundingClientRect().right;
                console.log('PIX Num. = ', i);
                console.log('?? Left = ', rectFourLeft);
                console.log('?? Right = ', rectFourRight);
            } 
        }
    } else { 
        for (let i = 0; i < pointCard.length; i++) {
            let resetPointCard = pointCard[i].style.left;
            pointCard[i].style.left = resetPointCard;
            arrowCard[i].style.display = 'block';
            console.log('PIX Num. = ', i);
        }
    }

    // const pointCard = document.querySelectorAll('.point-card');
    // const arrowCard = document.querySelectorAll('.arrow');
    // const resetPointCard = pointCard.style.left;
    
    // //var rect = pointCard.getBoundingClientRect();

    // var viewportWidth = window.innerWidth;
    // if (viewportWidth < 400) {
    //     // Apply styles for smaller screens & in portrait mode
    //     let rect = pointCard.getBoundingClientRect();
    //     console.log('Left = ', rect.left);
    //     console.log('Right = ', rect.right);
    //     var positionLeft = parseFloat(rect.left.toFixed(2))/parseFloat(viewportWidth.toFixed(2));
    //     var positionRight = parseFloat(rect.right.toFixed(2))/parseFloat(viewportWidth.toFixed(2));
    //     console.log('Position left = ', positionLeft);
    //     console.log('Position right = ', positionRight);
    //     if (positionLeft >= 0.1 && positionLeft <= 0.9 && positionRight >= 0.1 && positionRight <= 0.9) {
    //         console.log("Fit already");
    //     } else if (positionLeft < 0.1 && positionRight >= 0.1 && positionRight <= 0.9){
    //         console.log("Translated to the left, not fit initially");
    //         arrowCard.style.display = 'none';
    //         let b;
    //         b = window.innerWidth - rect.width;
    //         // pointCard.style.position = "relative";
    //         pointCard.style.marginLeft = b/2 + "px";
    //         let rectTwo = pointCard.getBoundingClientRect();
    //         console.log('After left = ', rectTwo.left);
    //         console.log('After right = ', rectTwo.right);
    //     } else if (positionLeft >= 0.1 && positionLeft <= 0.9 && positionRight > 0.9){
    //         console.log("Translated to the right, not fit initially");
    //         arrowCard.style.display = 'none';
    //         let b;
    //         b = window.innerWidth - rect.width;
    //         pointCard.style.marginLeft = - b/2 + "px";
    //         let rectThree = pointCard.getBoundingClientRect();
    //         console.log('After left = ', rectThree.left);
    //         console.log('After right = ', rectThree.right);
    //     } else {

    //     }
    // } 

    // Wrap viewport check in its own function
    // var checkViewportSize = () => {
    //     let rect = pointCard.getBoundingClientRect();
    //     console.log('Window Width = ', window.innerWidth)
    //     console.log('Window Height = ', window.innerHeight)
    //     console.log('Initial point card left = ', rect.left)
    //     console.log('Initial point card right = ', rect.right)
    //     if (window.innerWidth < 400) {
    //         // Apply styles for smaller screens & in portrait mode
    //         pointCard.style.left = "85%";
    //         console.log('Second point card left = ', rect.left)
    //         console.log('Second point card right = ', rect.right)
    //     } else {
    //         pointCard.style.left = resetPointCard;
    //         arrowCard.style.display = 'block';
    //     }
    //     var viewportHeight = window.innerHeight;
    //     var viewportWidth = window.innerWidth;  
    //     var isInViewport = (pointCard) => {
    //         var rect = pointCard.getBoundingClientRect();
    //         var positionLeft = rect.left/viewportWidth;
    //         var positionRight = rect.right/viewportWidth;
    //         if (positionLeft >= 0 && positionLeft <= 1 && positionRight >= 0 && positionRight <= 1) {
    //             console.log("Fit");
    //         }
    //         else if ((positionLeft >= 0 && positionLeft <= 1) || (positionRight >= 0 && positionRight <= 1)){
    //                 console.log("Not fit");
    //         }
    //     }
    //     isInViewport(pointCard);        
    // }
    // Now we'll assign this to events
    // Set event listener for window resize
    // window.addEventListener('resize', () => {
    //     checkViewportSize();
    // });
    // Set event listener for device orientation change
    window.onorientationchange = function() { window.location.reload(); };
    // window.addEventListener('orientationchange', () => {
    //     checkViewportSize();
    // });
    // let landscape = window.matchMedia("(orientation: landscape)");
    // landscape.addEventListener("change", function(e) {
    //     if(e.matches) {
    //         for (let i = 0; i < pointCard.length; i++) {
    //             let resetPointCard = pointCard[i].style.left;
    //             pointCard[i].style.left = resetPointCard;
    //             arrowCard[i].style.display = 'block';
    //         }
    //     } else {
    //             // Portrait
    //             if (viewportWidth < 400) {
    //                 // Apply styles for smaller screens & in portrait mode
    //                 pointCard.style.left = "300px";
    //             }
    //      }
    // })
    
    current_card.classList.add('show');
}

const close_cards = (target) => {
    [...document.querySelectorAll('.point')]
        .forEach(p => {
            p.nextSibling.style.display = 'none';
            p.nextSibling.classList.remove('show');
            if (p !== target && p.nextSibling.querySelector('iframe'))
                p.nextSibling.querySelector('iframe').src += '';
        });
}

function points_handler() {
    [...document.querySelectorAll('.point')]
        .forEach(p => p.addEventListener('click', open));
    [...document.querySelectorAll('.close-icon')]
        .forEach(p => p.addEventListener('click', close_cards));
}

function formWorker(imageId){
    $('.wp-block-aopg-blocks-formblock form.feedbackForm').each(function () {
        $(this).on('submit', function (e) {
            const form = $(this);
            e.preventDefault();
            const json = convertFormToJSON(form);
            const url = $(this).data('route');

            let parent = $(this).parent(),
                height = parent.innerHeight(),
                done = parent.find('.successText'),
                fail = parent.find('.failText');

            $.ajax({
                url: url + 'dcp3450/v1/form_endpoint',
                method: 'GET',
                dataType: 'json',
                data: {
                    formData: json,
                    imageId: imageId
                },
            }).done((resp) => {
                console.log(resp);
                parent.css('min-height', height + 'px');
                $(this).hide();
                done.show();
            })
                .fail((xhr, error, message) => {
                    // console.log(e);
                    parent.css('min-height', height + 'px');
                    $(this).hide();
                    fail.show();
                    console.log(xhr, error, message);
                });

        });
    });

    function convertFormToJSON(form) {
        return $(form)
            .serializeArray()
            .reduce(function (json, {name, value}) {
                json[name] = value;
                return json;
            }, {});
    }
}